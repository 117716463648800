/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  useAuth, useUser, cancelRequest,
} from 'react-omnitech-api'
import { useLink, useCart, useOrderMethod } from '../../hook'
import LogoutView from './logout-view'

function LogoutController({
  pageContext: {
    locale,
  },
}) {
  const { navigate } = useLink()
  const {
    deleteSession, auth, setAuth,
  } = useAuth()
  const { resetCart } = useCart()
  const { clearOrderMethod } = useOrderMethod()
  const [logoutSuccess, setLogoutSuccess] = useState(false)
  const { resetUser } = useUser()

  /**
   * handleLogOut
   */
  useEffect(() => {
    try {
      deleteSession()
    } catch (error) {
      console.log('Error :: Logout ', error)
    } finally {
      setAuth({
        ...auth,
        authToken: '',
        userId: '',
      })
      resetUser()
      resetCart()
      clearOrderMethod()
      setLogoutSuccess(true)
    }

    if (!logoutSuccess) return
    navigate('/', { replace: true })
    return () => {
      cancelRequest.cancelAll(['deleteSession'])
    }
  }, [logoutSuccess])

  return (
    <LogoutView />
  )
}

export default LogoutController
